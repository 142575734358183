import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import raw from 'rehype-raw'
import sanitize from 'rehype-sanitize'
import { defaultSchema as gh } from 'hast-util-sanitize'
import { Icon } from '@nike/eds'

import styles from '../../stylus/docs.styl'
import CodeBlock from './CodeBlock.js'
import ReactMarkdownHeading from './Headings/index.js'
import { scrollHashIntoView } from '../../util/scrollIntoView.js'
import { slugify } from '../../util/slugify.js'
import { getConfig } from '../../config.js'
import {
  toDataURL,
  setImageCache,
  removeTokenFromImageUrl,
} from '../../components/Content/cache.js'

// Allows className to get through sanitizer for syntax highlighting
gh.attributes = {
  ...gh.attributes,
  code: ['className'],
  video: ['src', 'autoplay', 'loop', 'muted'],
}
gh.tagNames = [...gh.tagNames, 'video']

const rehypePlugins = [raw, [sanitize, gh]]
const remarkPlugins = [gfm]

export default function Markdown({ components, source, onNavigate, showNav = false, ...props }) {
  const isLargeSrc = source?.length > 50000
  const [currentHash, setCurrentHash] = useState(window.location.hash)

  useEffect(() => {
    // React-markdown seems to have some async rendering
    // causing this to fail in many cases when run synchronously
    setTimeout(() => scrollHashIntoView(currentHash), 10)
  }, [source, currentHash])

  function onLocationChange(e) {
    onNavigate(e)
    if (e.target?.hash) {
      setCurrentHash(e.target.hash)
    }
  }

  useEffect(() => {
    const wrapper = document.querySelector('.EP-md-content')
    if (wrapper) {
      wrapper.addEventListener('click', onLocationChange)
      return () => wrapper.removeEventListener('click', onLocationChange)
    }
  }, [])

  return (
    <div className={styles.markdownContainer}>
      <div className={`${styles.markdownContent} ${isLargeSrc ? styles.noHeadings : ''}`}>
        <ReactMarkdown
          {...props}
          remarkPlugins={remarkPlugins}
          rehypePlugins={rehypePlugins}
          components={{
            code: CodeBlock,
            h1: HeadingRenderer,
            h2: HeadingRenderer,
            h3: HeadingRenderer,
            h4: HeadingRenderer,
            h5: HeadingRenderer,
            h6: HeadingRenderer,
            img: ImageRenderer,
            video: VideoRenderer,
            ...components,
          }}
        >
          {source}
        </ReactMarkdown>
      </div>
      {!showNav && !isLargeSrc && (
        <div className={styles.headingList}>
          <ReactMarkdownHeading markdown={source} hyperlink={true} activeHash={currentHash} />
        </div>
      )}
    </div>
  )
}

function HeadingRenderer(props) {
  const children = React.Children.toArray(props.children)
  const text = children.reduce(flatten, '')
  const slug = slugify(text)
  return React.createElement(
    props.node?.tagName || 'h3',
    { id: slug },
    <>
      <a
        className={styles['heading-anchor']}
        id={slug}
        href={`${window.location.pathname}#${slug}`}
      >
        <Icon size='m' name='Link' />
      </a>
      {props.children}
    </>
  )
}

function flatten(text, child) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function ImageRenderer({ node }) {
  const config = getConfig()
  const { properties } = node
  const originalSrc = properties.src
  const isGithubImage = originalSrc?.includes(config.githubCloudProxyHost)

  // Remove everything after `?token` in case there's different tokens involved
  const srcWithoutToken = removeTokenFromImageUrl(originalSrc)
  const initialSrc = isGithubImage ? window.imageCache[srcWithoutToken] || '' : originalSrc
  const [src, setSrc] = useState(initialSrc)

  useEffect(() => {
    async function loadImg() {
      let newSrc = originalSrc
      if (window.imageCache[srcWithoutToken]) {
        newSrc = window.imageCache[srcWithoutToken]
      } else {
        const url = new URL(originalSrc)
        newSrc = await toDataURL(url)
        newSrc = newSrc.replace('http://data:image', 'data:image')
        setImageCache({ [srcWithoutToken]: newSrc })
      }
      setSrc(newSrc)
    }

    if (isGithubImage && (!src || src !== window.imageCache[srcWithoutToken])) {
      loadImg()
    }
  }, [srcWithoutToken])

  return src ? <img {...properties} src={src} /> : null
}

function VideoRenderer({
  width = '640',
  height = '480',
  src,
  autoplay = false,
  controls = true,
  loop = false,
  muted = false,
}) {
  const videoAttributes = {
    width,
    height,
    autoPlay: autoplay,
    controls,
    loop,
    muted,
  }

  return (
    <video {...videoAttributes}>
      <source src={src} />
      Your browser does not support the video tag.
    </video>
  )
}
