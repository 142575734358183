import React, { useEffect, useRef, useState } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import svgPanZoom from 'svg-pan-zoom'
import syntaxes from 'react-syntax-highlighter/dist/cjs/prism'
import { IconButton } from '@nike/eds'
import mermaidjs from 'mermaid'
import styles from '../../stylus/docs.styl'

const mermaid = mermaidjs.default || mermaidjs
const { coy } = syntaxes

const defaultMermaidDocHeight = 800

function setDefaultSvgHeight(svgElement) {
  const { height: svgHeight } = svgElement.getBoundingClientRect()
  let height = svgHeight
  if (height > defaultMermaidDocHeight) {
    height = defaultMermaidDocHeight
  }
  svgElement.style = `width:100%;height:${height}px;`
}

async function loadMermaid(codeBlock, codeBlockId, code, setPanZoomControls, handleResize) {
  if (codeBlock.current) {
    try {
      mermaid.initialize({ startOnLoad: true, flowchart: { useMaxWidth: false } })
      const { svg: svgString } = await mermaid.render(codeBlockId.current, code)
      codeBlock.current.innerHTML = svgString

      // setup pan-zoom controls
      try {
        const svgElement = codeBlock.current.getElementsByTagName('svg')[0]
        if (svgElement) {
          // panZoom requires a height on the SVG
          setDefaultSvgHeight(svgElement)
          const panZoom = svgPanZoom(`#${codeBlockId.current}`, {
            controlIconsEnabled: true,
            mouseWheelZoomEnabled: false,
          })
          setPanZoomControls(panZoom)
          // This keeps the panZoom controls in the bottom right
          window.addEventListener('resize', handleResize)
        }
      } catch (error) {
        console.error('failed to load pan-zoom controls')
      }
    } catch (error) {
      codeBlock.current.innerHTML = error
    }
  }
}

function CodeBlock({ inline, className, children, ...props }) {
  const [copySuccess, setCopySuccess] = useState(false)
  const codeBlockId = useRef(`dome${randomid()}`)
  const code = getCode([children])
  const codeBlock = useRef(null)
  let panZoomControls = null

  function setPanZoomControls(controls) {
    panZoomControls = controls
  }

  function handleResize() {
    if (panZoomControls) {
      panZoomControls.resize()
      panZoomControls.center()
    }
  }

  useEffect(() => {
    loadMermaid(codeBlock, codeBlockId, code, setPanZoomControls, handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [code, codeBlock])

  if (
    typeof code === 'string' &&
    typeof className === 'string' &&
    /^language-mermaid/.test(className.toLocaleLowerCase())
  ) {
    return (
      <div className={styles.mermaidCode}>
        <code ref={codeBlock}>
          <code ref={codeBlock} id={codeBlockId.current} style={{ display: 'none' }} />
        </code>
      </div>
    )
  }

  async function copyCode(text) {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      console.error(error)
    }
    setCopySuccess(true)
    setTimeout(() => setCopySuccess(false), 1000)
  }

  const match = /language-(\w+)/.exec(className || '')
  let iconProps = copySuccess
    ? { className: styles.copySuccess, icon: 'Check' }
    : { className: '', onClick: () => copyCode(code), icon: 'CopyPaste' }

  const language = match && match[1]
  const codeWithSyntax = (
    <SyntaxHighlighter style={coy} language={language} PreTag='div' {...props}>
      {children}
    </SyntaxHighlighter>
  )

  return className && match ? (
    <div className={styles.codeCopy}>
      {codeWithSyntax}
      <IconButton variant='ghost' {...iconProps} />
    </div>
  ) : language ? (
    codeWithSyntax
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  )
}

function randomid() {
  return parseInt(String(Math.random() * 1e15), 10).toString(36)
}

function getCode(arr = []) {
  return arr
    .map((dt) => {
      if (typeof dt === 'string') {
        return dt
      }
      if (dt.props && dt.props.children) {
        return getCode(dt.props.children)
      }
      return false
    })
    .filter(Boolean)
    .join('')
}

export default CodeBlock
