import { useEffect, useState } from 'react'
import http from '../../util/http.js'
import { getConfig } from '../../config.js'

export function useContentCache({ tree, title, projectName, version }) {
  const platformName = projectName?.replace(/-/g, ' ')

  const [contentCache, setContentCache] = useState({})
  const [loading, setLoading] = useState(true)
  const [docError, setDocError] = useState(null)
  const isRoot = title === platformName || !title
  const defaultDoc = tree ? Object.keys(tree)[0] : null
  const doc = isRoot ? platformName : title || defaultDoc

  useEffect(() => {
    setContentCache({})
  }, [version, projectName])

  useEffect(() => {
    const fetchData = async () => {
      setDocError(null)
      if (contentCache[title]) return
      setLoading(true)

      if (!tree) return

      const href = tree[title] ? tree[title].href : isRoot ? tree[defaultDoc].href : null

      let response
      if (href) {
        try {
          response = await http.get(href)
        } catch (e) {
          setDocError(e.message)
        }
      } else {
        setLoading(false)
        return setDocError('Document not found')
      }

      const newContent = isRoot
        ? {
            ...contentCache,
            [defaultDoc]: {
              raw: response,
              title: defaultDoc,
              type: tree[defaultDoc].type,
              tree,
              version,
              projectName,
            },
            [platformName]: {
              raw: response,
              title: defaultDoc,
              type: tree[defaultDoc].type,
              tree,
              version,
              projectName,
            },
          }
        : {
            ...contentCache,
            [title]: {
              raw: response,
              title,
              type: tree[title] ? tree[title].type : null,
              tree,
              version,
              projectName,
            },
          }
      setContentCache(newContent)
      setLoading(false)

      setContentCache(await fetchNeighbors(tree, title, newContent))
    }

    fetchData().catch((error) => {
      console.error('content fetch error', error)
      throw error
    })
  }, [title, tree])

  return { content: contentCache[doc], loading, docError, title: title || defaultDoc }
}

// Image cache on the window object so not to mess with react state/rendering
window.imageCache = {}
export function setImageCache(newCache = {}) {
  window.imageCache = { ...window.imageCache, ...newCache }
}

export function removeTokenFromImageUrl(src = '') {
  return src.slice(0, src?.indexOf('?token'))
}

export async function toDataURL(url) {
  const getBase64 = async (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = function() {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })
  }
  const response = await fetch(url)
  const blob = await response.blob()
  return getBase64(blob)
}

/**
 * Github Image URLs have embedded short lived tokens in them. These tokens expire after 5 minutes.
 * Due to content caching, these tokens can expire and render broken images.
 * This function converts the Github Image URLs to Base64 data URLs.
 * Starting Version 2.2.25 this is only run when fetching neighbor content, the markdown image renderer handles "current" documents.
 * @param {string} markdownText markdown content
 * @returns {string} update markdown content
 */
async function cacheGithubImageUrls(markdownText) {
  const config = getConfig()

  if (typeof markdownText === 'string') {
    const pattern = /!\[[^\]]*\]\((.*?)\s*\)/gm // match image markdown
    const matches = markdownText.matchAll(pattern)
    const uniqueImageUrlList = Array.from(matches).filter(
      (match, i, self) => i === self.findIndex((m) => m[1].split(' ')[0] === match[1].split(' ')[0])
    )

    for (const match of uniqueImageUrlList) {
      let imageUrl = match[1]
      const tempUrl = imageUrl.split(' ')
      const hasOptionalTitle = tempUrl.length > 1
      imageUrl = (hasOptionalTitle && tempUrl[0]) || imageUrl
      const url = new URL(imageUrl)
      // only modify github image urls
      if (url.host === config.githubCloudProxyHost) {
        const newUrl = await toDataURL(imageUrl)
        const srcWithoutToken = removeTokenFromImageUrl(imageUrl)
        setImageCache({ [srcWithoutToken]: newUrl })
      }
    }
  }

  return markdownText
}

async function fetchNeighbors(tree, item, content) {
  if (!tree) return null

  const neighbors = getNeighborNames(tree, item)
  const neighborContent = neighbors.map((doc) => http.get(doc.info.href).catch((err) => err))

  const results = await Promise.all(neighborContent)
  let contentObj = {}

  for (let i = 0; i < results.length; i++) {
    const result = results[i]
    const { title } = neighbors[i]
    const type = tree[title].type

    let raw =
      typeof result === 'object' && (type === 'markdown' || type === 'asciidoc')
        ? result.message || result.toString()
        : result

    raw = await cacheGithubImageUrls(raw)
    contentObj[title] = { raw, title, type, tree }
  }

  return { ...content, ...contentObj }
}

function getNeighborNames(tree, docName) {
  if (!tree || !tree[docName]) return []
  const arr = tree[docName].parent
    ? [{ title: tree[docName].parent, info: tree[tree[docName].parent] }]
    : []

  for (let prop in tree) {
    if (prop !== docName) {
      if (
        (!!tree[prop].parent && tree[prop].parent === tree[docName].parent) ||
        tree[prop].parent === docName
      ) {
        arr.push({ title: prop, info: tree[prop] })
      }
    }
  }
  return arr
}
